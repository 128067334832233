import React from "react";
import { Card, CardDescription, CardContent, CardHeader, CardTitle } from "components/ui/card";
import AvatarConversation from "./AvatarConversation"
import { useParams } from "react-router-dom";
 
 
export default function AvatarDemoModeWeb() {

    const { oragID, subOragID } = useParams()

    return (
        <div className="flex gap-2 flex-wrap flex-grow">
            <Card >
                <CardHeader>
                    <CardTitle>Web Assistant Demo</CardTitle>
                    <CardDescription>
                        The BeX Assistant for Web Engagement is an advanced conversational AI platform designed to address the limitations and simplifies support and enhances user experiences. See how easily we can create and showcase a chatbot tailored to your enterprise's needs.
                    </CardDescription>
                </CardHeader>
                <CardContent className=" h-full w-full">
                    <AvatarConversation organizationId={oragID} subOrganizationId={subOragID} assistantId={'66864d52e79314bdffee06d9'} opCode={'Expert_Bot_Groq'} />
                </CardContent>
            </Card>
        </div>
    );
}