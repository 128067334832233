import { CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Button } from "components/ui/button"
import { Textarea } from 'components/ui/textarea'
import React, { useEffect, useState } from 'react'
import { MessageSquare, X } from 'lucide-react'

const AppearanceAndLauncher = ({loading, handleSave, emailUpdateObject}) => {
    const [greetingMessage, setGreetingMessage] = useState("Hi! I'm BeX AI Assistant. How can I help you today?")
    const [accentColor, setAccentColor] = useState("#000000")
    const [textAccentColor, setTextAccentColor] = useState("#ffffff")
    const [greetingMessageWidth, setGreetingMessageWidth] = useState(380)
    const [greetingMessageHeight, setGreetingMessageHeight] = useState(400)

    const handleSaveClick = () => {
        const webChatConfig = {
            ...emailUpdateObject?.webChatConfig,
            greetingMessage,
            accentColor,
            textAccentColor,
            greetingMessageWidth,
            greetingMessageHeight
        }

        handleSave(webChatConfig)
    }

    useEffect(() => {
        if(emailUpdateObject.webChatConfig){
            const {greetingMessage, accentColor, textAccentColor, greetingMessageWidth, 
                greetingMessageHeight} = emailUpdateObject.webChatConfig

            if (greetingMessage !== undefined) setGreetingMessage(greetingMessage);
            if (accentColor !== undefined) setAccentColor(accentColor);
            if (textAccentColor !== undefined) setTextAccentColor(textAccentColor);
            if (greetingMessageWidth !== undefined) setGreetingMessageWidth(greetingMessageWidth);
            if (greetingMessageHeight !== undefined) setGreetingMessageHeight(greetingMessageHeight);
        }
      
    }, [emailUpdateObject])
    
     
return (
    <div className='flex flex-col gap-4'>
        <CardHeader className="p-0">
            <CardTitle>Appearance & Launcher Configuration</CardTitle>
            <CardDescription>Customize the look and feel of your web chat and how it's launched</CardDescription>
        </CardHeader>
        <CardContent className="space-y-6 px-0 pb-0">
            <div className="space-y-4">
                <h3 className="text-lg font-semibold">Style</h3>
                <div className="space-y-2">
                    <Label htmlFor="accent-color">Accent color (Significant and interactive objects)</Label>
                    <div className="flex space-x-2">
                        <Input
                        type="color"
                        id="accent-color"
                        value={accentColor}
                        onChange={(e) => setAccentColor(e.target.value)}
                        className="w-12 h-12 p-1"
                        />
                        <Input
                        type="text"
                        value={accentColor}
                        onChange={(e) => setAccentColor(e.target.value)}
                        className="flex-grow"
                        />
                    </div>
                </div>
                <div className="space-y-2">
                    <Label htmlFor="accent-color">Text color</Label>
                    <div className="flex space-x-2">
                        <Input
                        type="color"
                        id="accent-color"
                        value={textAccentColor}
                        onChange={(e) => setTextAccentColor(e.target.value)}
                        className="w-12 h-12 p-1"
                        />
                        <Input
                        type="text"
                        value={textAccentColor}
                        onChange={(e) => setTextAccentColor(e.target.value)}
                        className="flex-grow"
                        />
                    </div>
                </div>
                <div className="space-y-2">
                    <Label htmlFor="chat-width">Width</Label>
                    <Input
                        id="chat-width"
                        type="number"
                        value={greetingMessageWidth}
                        onChange={(e) => setGreetingMessageWidth(Number(e.target.value))}
                        min={200}
                        max={800}
                    />
                </div>
                <div className="space-y-2">
                    <Label htmlFor="chat-height">Height</Label>
                    <Input
                        id="chat-height"
                        type="number"
                        value={greetingMessageHeight}
                        onChange={(e) => setGreetingMessageHeight(Number(e.target.value))}
                        min={300}
                        max={1000}
                    />
                </div>
            </div>
            <div className="space-y-4">
                <h3 className="text-lg font-semibold">Launcher</h3>
                <div className="space-y-2">
                    <Label htmlFor="greeting">Greeting Message</Label>
                    <Textarea
                        id="greeting"
                        value={greetingMessage}
                        onChange={(e) => setGreetingMessage(e.target.value)}
                        rows={3}
                    />
                </div>
            </div>
            <div className="mt-6">
                <Button className="w-full" disabled={loading} onClick={handleSaveClick} >
                    Save Changes
                </Button>
            </div>
        </CardContent>

        {/* chat widget Appearance & Launcher */}
        <div className="fixed bottom-10 right-4 flex flex-col items-end">
            <div 
                className="mb-4 shadow-xl rounded-lg overflow-y-auto relative"
                style={{ 
                width: `${greetingMessageWidth}px`,
                height: `${greetingMessageHeight / 4}px`,
                backgroundColor: accentColor,
                color:textAccentColor,
                }}
            >
                <button className="absolute top-2 right-2 hover:text-gray-200">
                    <X className="h-5 w-5" />
                </button>
                <div className="h-full p-4 pt-5 flex items-center justify-center">
                    <p 
                    className="text-xl font-light text-center leading-snug overflow-auto h-[90%]"
                    style={{
                        scrollbarWidth: 'none', 
                        msOverflowStyle: 'none',
                    }}
                    >
                        {greetingMessage}
                    </p>
                </div>
            </div>
            <button 
                className="rounded-full p-3 shadow-lg size-16 flex justify-center items-center"
                style={{ backgroundColor: accentColor, color:textAccentColor }}
            >
                <MessageSquare className="h-6 w-6" />
            </button>
        </div>
    </div>
)
}

export default AppearanceAndLauncher