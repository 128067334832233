import React, { createContext, useState, useEffect, useContext } from 'react';
import { unifiedModelService } from "api/services/PROJECT-O/UnifiedModel.service";
import { opCodeService } from "api/services/PROJECT-O/OpCodeBuilder.service";
import handler from '../components/OpcodeBuilder/OpCodeBuilderHandler';
import { OrganizationContext } from 'context/OrganizationContext';
import { SubOrgContext } from 'context/SubOrganizationContext';

export const OpCodeBuilderContext = createContext({});

export const OpCodeBuilderProvider = ({ children }) => {

    const [currentStep, setCurrentStep] = useState(0);
    const [savedModels, setSavedModels] = useState([]);
    const [savedModelsGroq, setSavedModelsGroq] = useState([]);
    const [opCodes, setOpCodes] = useState([]);
    const [opCodesDetails, setOpCodesDetails] = useState([]);
    const [selectedOpCodesDetails, setSelectedOpCodesDetails] = useState({});
    const [isModelLoading, setIsModelLoading] = useState([]);
    const [isOpcodeLoading, setIsOpcodeLoading] = useState([]);

    // Create OpCode
    const [opCodeName, setOpCodeName] = useState("");
    const [opCodeDescription, setOpCodeDescription] = useState("");
    const [inputGroups, setInputGroups] = useState([]);
    const [isCreating, setIsCreating] = useState(false);


    // Execute OpCode
    const [selectedOpCode, setSelectedOpCode] = useState("");
    const [contextKey, setContextKey] = useState("");
    const [executeInput, setExecuteInput] = useState("");
    const [executionResults, setExecutionResults] = useState([]);
    const [isExecuting, setIsExecuting] = useState(false);

    const { selectedOrganization } = useContext(OrganizationContext);
    const { selectedSubOrganization } = useContext(SubOrgContext);
    

    const handleGetSavedModels = async () => {
        try {
            setIsModelLoading(true);
            console.log("getting models");
    
            // First API call with "IBM" as the third argument
            const ibmRes = await unifiedModelService.GetModelsByOrgSubOrg(selectedOrganization._id, selectedSubOrganization._id, "IBM");
            console.log("IBM response: ", ibmRes);
    
            // Second API call with "Groq" as the third argument
            const groqRes = await unifiedModelService.GetModelsByOrgSubOrg(selectedOrganization._id, selectedSubOrganization._id, "Groq");
            console.log("Groq response: ", groqRes);
    
            // Sort and set the combined models
            setSavedModels(ibmRes.data.FoundationModels.sort());
            setSavedModelsGroq(groqRes.data.FoundationModels.sort());
        } catch (error) {
            console.error("Failed to get saved models", error);
        } finally {
            setIsModelLoading(false);
        }
    };
    
    
    const handleGetSavedOpcodes = async () => {
        try {
            setIsOpcodeLoading(true)
            console.log("getting opcodes")
            const res = await opCodeService.getOpCodesByOrgSubOrg(selectedOrganization._id, selectedSubOrganization._id);
            console.log(res);
            setOpCodes(res.data.OpCodes)
            // setOpCodesDetails(res.data.opcodes)
            // const opCodes = res.data.opcodes.map(opcode => opcode.opcode_id);
            // console.log("opcodes: ", opCodes)
            // setOpCodes(opCodes.sort());
        } catch (error) {
            console.error("Failed to get saved models", error);
        } finally {
            setIsOpcodeLoading(false)
        }
    };

    function validateSteps(steps) {
        let errorMessages = [];
        
        steps.forEach((step, index) => {
            let stepErrors = [];
    
            // Validate LLM steps
            if (step.step_type === "LLM") {
                if (!step?.model_type) {
                    stepErrors.push("no model type");
                }
                if (!step?.unique_name) {
                    stepErrors.push("no instruction");
                }
                // Check if dynamic_inputs is an empty list
                if (!Array.isArray(step.dynamic_inputs) || step.dynamic_inputs.length === 0) {
                    stepErrors.push("no input");
                } else {
                    // Validate each dynamic input if it's a string
                    step.dynamic_inputs.forEach(input => {
                        if (typeof input === 'string') {
                            const { isValid, message } = handler.validateInputName(input);
                            if (!isValid) {
                                stepErrors.push(`invalid input: ${message}`);
                            }
                        }
                    });
                }
            } 
            // Validate Non-LLM steps
            else if (step.step_type === "Non-LLM") {
                if (step.registry_type === "function" && !step?.registry_key) {
                    stepErrors.push("no function selected");
                }
                if (step.registry_type === "api" && !step?.registry_key) {
                    stepErrors.push("no function selected");
                }
                if (!step?.fixed_inputs || Object.keys(step.fixed_inputs).length === 0) {
                    stepErrors.push("no fixed inputs");
                }
                // Check if dynamic_inputs.query_text exists and is either a string or list with values
                if (!step?.dynamic_inputs?.query_text) {
                    stepErrors.push("no dynamic input");
                } else if (typeof step.dynamic_inputs.query_text === 'string') {
                    // Validate query_text if it's a string
                    const { isValid, message } = handler.validateInputName(step.dynamic_inputs.query_text);
                    if (!isValid) {
                        stepErrors.push(`invalid dynamic input (query_text): ${message}`);
                    }
                } else if (Array.isArray(step.dynamic_inputs.query_text) && step.dynamic_inputs.query_text.length === 0) {
                    stepErrors.push("dynamic input (query_text) is an empty list");
                }
            }
    
            // Validate output_var for both step types
            if (!step?.output_var) {
                stepErrors.push("no output variable");
            } else {
                const { isValid, message } = handler.validateOutputName(step.output_var);
                if (!isValid) {
                    stepErrors.push(`invalid output variable: ${message}`);
                }
            }
    
            if (stepErrors.length > 0) {
                errorMessages.push(`Step ${index + 1} has ${stepErrors.join(', ')}.`);
            }
        });
    
        return {
            isValid: errorMessages.length === 0,
            message: errorMessages.length > 0 ? errorMessages.join(' ') : "All steps are valid."
        };
    }
    
    
 
    useEffect(() => {
        handleGetSavedModels();
        handleGetSavedOpcodes()
    }, []);

    const value={
        currentStep, setCurrentStep,
        savedModels, setSavedModels,
        savedModelsGroq, setSavedModelsGroq,
        opCodes, setOpCodes,
        opCodesDetails,
        selectedOpCodesDetails, setSelectedOpCodesDetails,
        isModelLoading, setIsModelLoading,
        isOpcodeLoading, setIsOpcodeLoading,
        handleGetSavedModels,
        handleGetSavedOpcodes,


        opCodeName, setOpCodeName,
        opCodeDescription, setOpCodeDescription,
        inputGroups, setInputGroups,
        isCreating, setIsCreating,
        selectedOpCode, setSelectedOpCode,
        contextKey, setContextKey,
        executeInput, setExecuteInput,
        executionResults, setExecutionResults,
        isExecuting, setIsExecuting,

        validateSteps,
    }

    return (
        <OpCodeBuilderContext.Provider value={value}>
            {children}
        </OpCodeBuilderContext.Provider>
    );
};