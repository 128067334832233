/* eslint-disable max-lines */
import { useState, useContext, useEffect, useRef } from 'react';
import { Send, Settings, RotateCcw, FolderClosed } from 'lucide-react';
import { useParams } from "react-router-dom";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Slider } from "components/ui/slider";
import { Switch } from "components/ui/switch";
import { Label } from "components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { ScrollArea } from "components/ui/scroll-area";
import { DocumentList } from './DocumentList';
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "../HotelOpsStyles.css";
import { SubOrgContext } from "context/SubOrganizationContext";
import { OrganizationContext } from "context/OrganizationContext";
import { HotelOpsContext } from '../HotelOpsContext';
import hoHelper from '../HotelOpsHelper';
import { Context } from 'context/GlobalState';
import { milvusService } from 'api/services/PROJECT-O/Milvus.service';
import { CardContent} from "components/ui/card";
import AvatarConversation from "../../WebEngagement/Components/AvatarConversation";

export default function HotelOpDemo() {
    const {
        messages, setMessages,
        uploadedDocs,
    } = useContext(HotelOpsContext);
    const { user, addNewNotifcation } = useContext(Context);
    const { selectedOrganization } = useContext(OrganizationContext);
    const { selectedSubOrganization } = useContext(SubOrgContext);
    const [input, setInput] = useState('');
    const [showConfig, setShowConfig] = useState(false);
    const [selectedCollectionId, setSelectedCollectionId] = useState('');
    const [dataStore, setDataStore] = useState('');
    const [aiConfig, setAIConfig] = useState({
        wordLimit: 100,
        outputFormat: 'paragraph',
        includeReferences: false,
        includeImages: false
    });
    const [iframeSrc, setIframeSrc] = useState("");
    const [keyUpdate, setKeyUpdate] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { project } = useContext(SubOrgContext);
    const [selectedMode, setSelectedMode] = useState('query');
    const scrollRef = useRef(null);
    const { oragID, subOragID } = useParams()

    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    };

    const handleSend = async () => {
        if (input.trim()) {
            if (!selectedCollectionId) {
                addNewNotifcation("Please select a collection before querying.", "warning");
                return; 
            }   
    
            setMessages(prev => [...prev, { id: prev.length + 1, role: "user", content: input }]);
            setInput('');
            scrollToBottom();
            setIsLoading(true);
    
            try {
                const body = {
                    organization_id: selectedOrganization._id,
                    sub_organization_id: selectedSubOrganization._id,
                    collection_id: selectedCollectionId,
                    query_text: input,
                    top_k: 10,
                    offset: 0,
                    top_n: 3,
                    use_rerank: true,
                    filters: {},
                };
    
                const MilvusRAGresponse = await milvusService.hybridSearch(body);
                console.log("HybridSearch Response:", MilvusRAGresponse);
    
                const documents = MilvusRAGresponse.data.results;
                console.log("Response from Milvus:", documents);
    

                const ragData = hoHelper.prepareRAGReferenceMapping(documents);
                console.log("RAG data:", ragData);
    
                const ConstructedRAG = await hoHelper.constructMilvusRetrievedResults(documents, MilvusRAGresponse);
                const prompt = hoHelper.getPromptByMode(selectedMode, ConstructedRAG, input, aiConfig);
                const AIResponse = await hoHelper.ConstructPromptGroq(prompt, input, aiConfig);
                let responseContent = AIResponse.data.result?.prediction;
    
                if (responseContent) {
                    console.log("Original AI Response Content:\n", responseContent);
                    responseContent = hoHelper.updateReferencesWithLinks(responseContent, ragData);
                    console.log("Updated AI Response Content:\n", responseContent);
                    setMessages(prev => [...prev, { id: prev.length + 1, role: "assistant", content: responseContent }]);
                }
            } catch (error) {
                console.error("Error during handleSend:", error);
            } finally {
                setIsLoading(false);
                scrollToBottom();
            }
        }
    };
    
   
    const handleClear = () => {
        setMessages([{ id: 1, role: "assistant", content: "Hi! Feel free to ask questions and query from your documents." }]);
        setIframeSrc("");
        setKeyUpdate(1);
    }

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        const md = document.querySelectorAll(".custom-markdown");
        const links = [];
        md.forEach(parent => {
            const childLinks = parent.querySelectorAll("a");
            links.push(...childLinks);
        });
        links.forEach(link => {
            link.addEventListener("click", (event) => {
                event.preventDefault();
                setIframeSrc("");
                setKeyUpdate(prev => prev + 1);
                setIframeSrc(link.href);
            });
        });
    }, [messages]);
    

    return (
        <div className="flex h-full w-full bg-gray-100">
            {selectedMode === "avatar" ? (

                <div className="w-full p-3 bg-white flex flex-col rounded-lg shadow overflow-hidden h-full">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-bold ml-2">Hi, I am your Avatar!</h2>

                        <div className="flex items-center space-x-0">
                            <div className="p-2">
                                <Select
                                        value={selectedMode}
                                        onValueChange={(value) => setSelectedMode(value)}
                                    >
                                    <SelectTrigger id="mode-select" className="w-[180px]">
                                        <SelectValue placeholder="Select Mode" />
                                    </SelectTrigger>
                                    <SelectContent className="w-56">
                                        <SelectItem value="query">
                                            <div>
                                                <span className="font-bold">Query Mode</span>
                                                <p className="text-xs text-gray-500">Direct questions to get specific answers.</p>
                                            </div>
                                        </SelectItem>
                                        <SelectItem value="expert">
                                            <div>
                                                <span className="font-bold">Domain Expert Mode</span>
                                                <p className="text-xs text-gray-500">Engage in a conversational, expert-guided chat.</p>
                                            </div>
                                        </SelectItem>
                                        <SelectItem value="quiz">
                                            <div>
                                                <span className="font-bold">Quiz Mode</span>
                                                <p className="text-xs text-gray-500">Generate questions to test your knowledge.</p>
                                            </div>
                                        </SelectItem>
                                        <SelectItem value="teacher">
                                            <div>
                                                <span className="font-bold">AI Teacher Mode</span>
                                                <p className="text-xs text-gray-500">Receive guided explanations and answers.</p>
                                            </div>
                                        </SelectItem>
                                        <SelectItem value="student">
                                            <div>
                                                <span className="font-bold">AI Student Mode</span>
                                                <p className="text-xs text-gray-500">Learn through exploration and discovery.</p>
                                            </div>
                                        </SelectItem>
                                        <SelectItem value="avatar">
                                            <div>
                                                <span className="font-bold">Avatar Mode</span>
                                                <p className="text-xs text-gray-500">Talk real time with our Expert Avatar.</p>
                                            </div>
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <CardContent className="h-full w-full rounded-lg p-1 pl-4 pr-4">
                        <AvatarConversation
                            organizationId={oragID}
                            subOrganizationId={subOragID}
                            assistantId={'66daa0018370f6a851642c41'}
                            opCode={'Expert_Bot_Groq'}
                        />
                    </CardContent> 
                </div>

            ) : (
                <>
                    {/* Document Display */}
                    <div className="w-1/2 p-4 bg-white flex flex-col rounded-l-lg shadow overflow-hidden">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold ml-2">Document</h2>

                            <div className="flex items-center space-x-0">
                                <div className="p-2">
                                    <Select
                                        value={selectedMode}
                                        onValueChange={(value) => setSelectedMode(value)}
                                    >
                                        <SelectTrigger id="mode-select" className="w-[180px]">
                                            <SelectValue placeholder="Select Mode" />
                                        </SelectTrigger>
                                        <SelectContent className="w-56">
                                            <SelectItem value="query">
                                                <div>
                                                    <span className="font-bold">Query Mode</span>
                                                    <p className="text-xs text-gray-500">Direct questions to get specific answers.</p>
                                                </div>
                                            </SelectItem>
                                            <SelectItem value="expert">
                                                <div>
                                                    <span className="font-bold">Domain Expert Mode</span>
                                                    <p className="text-xs text-gray-500">Engage in a conversational, expert-guided chat.</p>
                                                </div>
                                            </SelectItem>
                                            <SelectItem value="quiz">
                                                <div>
                                                    <span className="font-bold">Quiz Mode</span>
                                                    <p className="text-xs text-gray-500">Generate questions to test your knowledge.</p>
                                                </div>
                                            </SelectItem>
                                            <SelectItem value="teacher">
                                                <div>
                                                    <span className="font-bold">AI Teacher Mode</span>
                                                    <p className="text-xs text-gray-500">Receive guided explanations and answers.</p>
                                                </div>
                                            </SelectItem>
                                            <SelectItem value="student">
                                                <div>
                                                    <span className="font-bold">AI Student Mode</span>
                                                    <p className="text-xs text-gray-500">Learn through exploration and discovery.</p>
                                                </div>
                                            </SelectItem>
                                            <SelectItem value="avatar">
                                                <div>
                                                    <span className="font-bold">Avatar Mode</span>
                                                    <p className="text-xs text-gray-500">Talk real time with our Expert Avatar.</p>
                                                </div>
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>

                                {/* Collection Select or Document List */}
                                <div className="p-2">
                                    <Select
                                        value={selectedCollectionId}
                                        onValueChange={(value) => setSelectedCollectionId(value)}
                                    >
                                        <SelectTrigger id="collection-select" className="flex items-center w-[190px]">
                                            <FolderClosed className="h-4 w-4 mr-2 text-gray-500" />
                                            <SelectValue placeholder="Select collection">
                                                <span
                                                    className="truncate block max-w-[140px]"
                                                    title={
                                                        project.collections.find(
                                                            (collection) => collection._id === selectedCollectionId
                                                        )?.name || selectedCollectionId
                                                    }
                                                >
                                                    {project.collections.find(
                                                        (collection) => collection._id === selectedCollectionId
                                                    )?.name || selectedCollectionId}
                                                </span>
                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            {project.collections.map((collection) => (
                                                <SelectItem key={collection._id} value={collection._id}>
                                                    <div className="flex items-center space-x-2">
                                                        <FolderClosed className="h-4 w-4 text-gray-500" />
                                                        <span>{collection.name || collection._id}</span>
                                                    </div>
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </div>

                        {iframeSrc === "" ? (
                            <div className="h-full w-full bg-gray-100 flex items-center justify-center">
                                <span>Select a reference to show the document.</span>
                            </div>
                        ) : (
                            <iframe
                                key={keyUpdate}
                                className="h-full"
                                src={iframeSrc}
                                width="100%"
                                height="100%"
                            ></iframe>
                        )}
                    </div>

                    {/* Chat Interface */}
                    <div className="w-1/2 flex flex-col bg-white rounded-r-lg shadow overflow-y max-w-[600px]">
                        <style>
                            {`
                            .typing-loader {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                height: 20px;
                                margin: 5px 0;
                                padding-left: -5px; 
                            }
                            .typing-loader div {
                                width: 8px; 
                                height: 8px;
                                margin: 0 2px;
                                background-color: black; 
                                border-radius: 50%;
                                animation: bounce 1.5s infinite ease-in-out;
                            }
                            .typing-loader div:nth-child(1) {
                                animation-delay: -0.32s;
                            }
                            .typing-loader div:nth-child(2) {
                                animation-delay: -0.16s;
                            }
                            @keyframes bounce {
                                0%, 80%, 100% {
                                    transform: scale(0);
                                }
                                40% {
                                    transform: scale(1);
                                }
                            }
                        `}
                        </style>

                        <ScrollArea ref={scrollRef} className="flex-grow p-4">
                            {messages.map(message => (
                                <div key={message.id} className={`mb-4 ${message.role === 'user' ? 'text-right' : 'text-left'}`}>
                                    <div
                                        className={`inline-block p-2 rounded-lg whitespace-pre-wrap text-sm ${message.role === 'user' ? 'bg-black text-white' : 'bg-gray-100 text-gray-800'}`}
                                    >
                                        <Markdown className="custom-markdown" remarkPlugins={[remarkGfm]}>
                                            {message.content}
                                        </Markdown>
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <div className="mb-4 typing-loader">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            )}
                        </ScrollArea>
                        <div className="p-4 bg-white border-t">
                            <div className="flex items-center space-x-2 mb-2">
                                <Input
                                    type="text"
                                    placeholder="Type your message..."
                                    value={input}
                                    onChange={(e) => setInput(e.target.value)}
                                    onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                                    className="w-full max-w-full overflow-hidden break-words"
                                    style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}
                                />
                                <Button onClick={handleSend}>
                                    <Send className="h-4 w-4" />
                                    <span className="sr-only">Send</span>
                                </Button>
                                <Button onClick={handleClear} variant="outline">
                                    <RotateCcw className="h-4 w-4" />
                                    <span className="sr-only">Clear</span>
                                </Button>
                                <Button onClick={() => setShowConfig(!showConfig)} variant="outline">
                                    <Settings className="h-4 w-4" />
                                    <span className="sr-only">Settings</span>
                                </Button>
                            </div>
                            {showConfig && (
                                <div className="bg-gray-100 p-4 rounded-lg space-y-4">
                                    <div>
                                        <Label htmlFor="word-limit">Word Limit: {aiConfig.wordLimit}</Label>
                                        <Slider
                                            id="word-limit"
                                            min={50}
                                            max={500}
                                            step={10}
                                            value={[aiConfig.wordLimit]}
                                            onValueChange={(value) => setAIConfig({ ...aiConfig, wordLimit: value[0] })}
                                        />
                                    </div>
                                    <div>
                                        <Label htmlFor="output-format">Output Format</Label>
                                        <Select
                                            value={aiConfig.outputFormat}
                                            onValueChange={(value) => setAIConfig({ ...aiConfig, outputFormat: value })}
                                        >
                                            <SelectTrigger id="output-format">
                                                <SelectValue placeholder="Select format" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="paragraph">Paragraph</SelectItem>
                                                <SelectItem value="bullet">Bullet Points</SelectItem>
                                                <SelectItem value="table">Tabulation</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Switch
                                            id="include-references"
                                            checked={aiConfig.includeReferences}
                                            onCheckedChange={(checked) => setAIConfig({ ...aiConfig, includeReferences: checked })}
                                        />
                                        <Label htmlFor="include-references">Include References</Label>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>

    );
}
