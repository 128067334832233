import React, { useEffect, useState, useContext } from 'react'
import { Button } from "components/ui/button"
import { DialogFooter } from "components/ui/dialog"
import { Label } from "components/ui/label"
import { Input } from "components/ui/input"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Context } from "context/GlobalState"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"
import { assistantInstanceService, channelService } from "api"
import CodeDisplay from 'components/ui/CodeDisplay'
import { v4 as uuidv4 } from 'uuid';
import { server } from 'utils/httpsRequest'
import { PlusIcon, XIcon } from 'lucide-react'

const isValidUrl = (url) => {
    try {
        new URL(url)
        return true
    } catch {
        return false
    }
}

export const NewChannelInput = ({ newChannelObj={}, channelType, onSubmit, setOpenEmailModal, isUpdate=false }) => {

    const [channelUrls, setChannelUrls] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    
    const [channelData, setChannelData] = useState({
        type: 'Webchat',
        topic: '',
        address: uuidv4(),
        assistantId: '',
        origins: [],
        includeConvoHistory: false,
        ...newChannelObj, // Merge initialData into the state for update
    });
    const [assistants, setAssistants] = useState([])
    const [assistantsLoading, setAssistantsLoading] = useState(true)

    const { addNewNotifcation } = useContext(Context)

    const { oragID, subOragID } = useParams()


    const handleChange = (value, type) => {
        setChannelData(prev => {
            return {...prev, [type]: value}
        })
    }

    const isButtonDisabled = () => {
        return (
        channelData.topic === '' ||
        // channelData.assistantId === '' ||
        channelData.address === ''
        );
    };

    useEffect(() => {
        handleChange(channelUrls, 'origins')
    }, [channelUrls])
      
    useEffect(() => {
        setChannelUrls(newChannelObj?.origins || [])

        setAssistantsLoading(true)
        assistantInstanceService.getSubOrgAssistantInstance(oragID, subOragID)
        .then((res) => {
            console.log('res: ', res);
            // filter only selected channel assistants
            setAssistants(res.data.filter((item) => item.assistantId.channelType === (channelType || '')))
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setAssistantsLoading(false)
        })
    }, [])

    const handleSubmit = () => {
       
        // check origins
        if (channelData.origins.some(item => item === '' || !isValidUrl(item))) {
            return addNewNotifcation('Please enter a valid URL', 'warning')
        }
        setBtnLoading(true)
        onSubmit(channelData)
        .finally(() => setBtnLoading(false))
    };

    const code = `<script>
    (function() {
        window.widgetId = '${channelData?.address || ''}';
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '${server}/static/dist/assets/chat-widget.css'; 
        link.type = 'text/css';
        document.head.appendChild(link);
        var script = document.createElement('script');
        script.src = '${server}/static/dist/assets/chat-widget.js'; 
        script.async = true;
        script.onload = function() {
          console.log('Widget loaded successfully!');
        };
        document.head.appendChild(script);
    })();
</script>`

    return (
        <div className='flex flex-col gap-4'>
            <div className="flex flex-col gap-4">
                <Label htmlFor="channel-type">
                    Address
                </Label>
                <Input 
                value={channelData.address} 
                disabled={true} onChange={(e) => handleChange(e.target.value, 'address')} />
            </div>
            <div className="flex flex-col gap-4">
                <Label htmlFor="channel-type">
                    Topic
                </Label>
                <Input disabled={!channelType} value={channelData.topic} onChange={(e) => handleChange(e.target.value, 'topic')} placeholder='Enquires, Reservation, ...' />
            </div>
            <div className="space-y-2 flex flex-col mt-3">
                <Label htmlFor="topic">Allowed Domains</Label>
                <span className='text-sm text-muted-foreground mt-1'>Add URLs to manage secure origins. Only listed URLs will be allowed.</span>
                <WorkflowMetadata data={channelUrls} setData={setChannelUrls} />
            </div>
            <div className="flex flex-col gap-4">
                <Label htmlFor="channel-type">
                    Assistant 
                </Label>
                <Select onValueChange={(value) => handleChange(value, 'assistantId')} disabled={assistantsLoading || !channelType} id="assistant" defaultValue={channelData.assistantId || 'none'}>
                    <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select an assistant" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="none">None</SelectItem>
                        {assistants.map(item => (
                            <SelectItem key={item._id} value={item._id}>{item.title}</SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <div className="flex flex-col gap-4">
                <Label htmlFor="channel-type">
                    Converstaion History
                </Label>
                <div className="flex items-center gap-2">
                    <Input
                    type ="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    id="includeHistory"
                    name="includeHistory"
                    checked={channelData.includeConvoHistory}
                    onChange={(e) => handleChange(e.target.checked, 'includeConvoHistory')}
                    />
                    Include conversation history
                </div>
            </div>
            {isUpdate && (
                <div className="space-y-2">
                    <Label>Embed Web Chat</Label>
                    <CodeDisplay code={code} />
                </div>
            )}
            <DialogFooter className='mt-3'>
                <Button onClick={() => setOpenEmailModal(false)} variant="outline">Cancel</Button>
                <Button onClick={handleSubmit} disabled={btnLoading || isButtonDisabled()}> Submit </Button>
            </DialogFooter>
        </div>
    )
}

export default function WebChatSetup({ setOpenEmailModal, setEmailChannels }) {

    const navigate = useNavigate();
    const location = useLocation();

    const { addNewNotifcation } = useContext(Context)

    const { oragID, subOragID } = useParams()


    const handleOnSubmit = async (newChannelObj) => {

        try {
            console.log('newChannelObj: ', newChannelObj);
            newChannelObj["organizationId"] = oragID
            newChannelObj["subOrganizationId"] = subOragID

            const res = await channelService.addChannel(newChannelObj)

            addNewNotifcation('Channel added successfully', 'success')
            setOpenEmailModal(false)
            setEmailChannels(prev => ([...prev, res.data]))
            // clean the url form queries (email address, etc...)
            const newUrl = location.pathname;
            navigate(newUrl, { replace: true });
        } catch(error) {
            console.log('error: ', error);
            if (error.response.status === 409) {
                addNewNotifcation('Channel already exists', 'danger')
            } else {
                addNewNotifcation('Something went wrong', 'danger')
            }
        }
    }

    return (
        <div>
            <NewChannelInput
            channelType={'web-chat'} setOpenEmailModal={setOpenEmailModal} 
            onSubmit={handleOnSubmit} />
        </div>
    )
}

const WorkflowMetadata = ({ data, setData }) => {
    
    const [isEmpty, setIsEmpty] = useState(false)

    const { addNewNotifcation } = useContext(Context)

    useEffect(() => {
        setIsEmpty(data.some(str => str === ''))
    }, [data])

    const addMetadata = () => {
        if (data.some(str => !isValidUrl(str))) {
            return addNewNotifcation('Please enter a valid URL', 'warning')
        }
        setData([...data, ''])
    }

    const removeMetadata = (index) => {
        const updatedMetadata = [...data]
        updatedMetadata.splice(index, 1)
        setData(updatedMetadata)
    }

    const handleMetadataChange = (index, value) => {
        const updatedMetadata = [...data]
        updatedMetadata[index] = value
        setData(updatedMetadata)
    }


    return (
        <div>
            <div className='flex flex-col gap-2'>
                {data.map((item, index) => (
                    <div key={index} className="flex gap-2">
                        <Input
                            className='flex-grow'
                            placeholder="https://example.com"
                            value={item}
                            onChange={(e) => handleMetadataChange(index, e.target.value)}
                        />
                        <Button type="button" variant="ghost" size="icon" onClick={() => removeMetadata(index)}>
                            <XIcon className="h-4 w-4" />
                            <span className="sr-only">Remove data</span>
                        </Button>
                    </div>
                ))}
            </div>
            <Button disabled={isEmpty} type="button" className='mt-2' variant="ghost" onClick={addMetadata}>
                <PlusIcon className="h-4 w-4 mr-2" />
                Add Url
            </Button>
        </div>
    )
}
