import React, { useEffect, useRef, useState } from 'react'
import { MessageSquare } from 'lucide-react'
import { io } from "socket.io-client";
import WebchatHeader from './WebchatWidget/WebchatHeader';
import WebchatBody from './WebchatWidget/WebchatBody';
import WebchatFooter from './WebchatWidget/WebchatFooter';

const WebchatWidget = ({chatSettings, widgetId, includeConvoHistory, iconStyle}) => {
    const [showChat, setShowChat] = useState(false);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [conversationHistory, setConversationHistory] = useState('History:\n')
    const [isHome, setIsHome] = useState(true)
    const socket = useRef(null);
    const userId = useRef(null);
    const conversationId = useRef(null);    
    const base_url = window.location.origin;
    const {primaryColor, textPrimaryColor, assistantName, chatWidth} = chatSettings

      
    useEffect(() => {
       if (!userId.current) {
            userId.current = localStorage.getItem("user_id") || `user-${Math.random().toString(36).substr(2, 9)}`;
            localStorage.setItem("user_id", userId.current);
        }

        if (!conversationId.current) {
            conversationId.current =
                sessionStorage.getItem("conversation_id") ||
                `tab-${userId.current}-${Date.now().toString(36)}-${Math.random().toString(36).substr(2)}`;
            sessionStorage.setItem("conversation_id", conversationId.current);
        }

        socket.current = io("https://feo.coolriots.ai/", {
            path: "/feo-v3/api/socketio",
        });

        socket.current.on("message", (data) => {
            handleStoreConvoHistory("bot_response", data)
            
            if(messages.length > 0) {
              setMessages((prevMessages) => [
              ...prevMessages,
              { text: data, type: "bot" },
              ]);
              setLoading(false);
            }
        });

        return () => {
            socket.current.disconnect();
        };
    }, []);


    const toggleChat = () => {
        setShowChat((prev) => !prev);
    };

    const emitMessage = (message = null) => {
        if(message) {
            setIsHome(false)
            const newMessage = { text: message, type: "user" };
            setMessages((prevMessages) => [...prevMessages, newMessage]);
    
            const newUserMessage = includeConvoHistory? `${conversationHistory}user_input: ${message}` : message
            handleStoreConvoHistory("User", message)
    
            setLoading(true);
            socket.current.emit("message", {
                widgetId,
                message:newUserMessage,
                user_id: userId.current,
                conversation_id: conversationId.current,
                base_url: base_url,
            });
        }
    }

    const handleStoreConvoHistory = (sender, message) => {
        if(includeConvoHistory){
            const convoMessage = `${sender}: ${message}\n`;
            setConversationHistory((prevHistory) => prevHistory + convoMessage);
        }
    }

    const handleClearChat = () => {
        setLoading(false)
        setMessages([])
        if(includeConvoHistory) setConversationHistory('History:\n')
    } 
      
    if(showChat) {
      return (
          <button 
          className="fixed bottom-0 right-4 rounded-full p-3 shadow-lg size-16 flex justify-center items-center"
          style={{ backgroundColor: iconStyle.accentColor, color:iconStyle.textAccentColor }}
          onClick={toggleChat}
          >
              <MessageSquare className="h-6 w-6" />
          </button>
      )
    }

    return (
        <div 
        className="fixed bottom-0 right-4 shadow-xl rounded-lg overflow-hidden border border-[#ccc] z-50"
        style={{ 
            width: `${chatWidth}px`,
            backgroundColor: isHome? primaryColor : "white",
            color: isHome? textPrimaryColor : "black",
        }}
        >
            <WebchatHeader
            assistantName={assistantName}
            handleClearChat={handleClearChat}
            setIsHome={setIsHome}
            toggleChat={toggleChat}
            />

            <WebchatBody
            emitMessage={emitMessage}
            messages={messages}
            isHome={isHome}
            loading={loading}
            chatSettings={chatSettings}
            />

            <WebchatFooter
            loading={loading}
            emitMessage={emitMessage}
            />        
        </div>
    )
}

export default WebchatWidget