import FileManager from 'components/FileManager/FileManager'
import { SubOrgContext } from 'context/SubOrganizationContext'
import React, { useContext, useState } from 'react'
import Breadcrumb from 'components/Breadcrumb';
import { Layers } from 'lucide-react';
import ProcessModal from './ProcessModal';
import L2ProcessingResult, { L2ProcessingDialog } from './L2ProcessingModal';
import { Context } from '../../../../../../context/GlobalState';

export default function FileManagerDocument() {
    const [isProcessing, setIsProcessing] = useState(false);
    const [isProcessing2, setIsProcessing2] = useState(false);
    const [isProcessing2Result, setIsProcessing2Result] = useState(false);
    const [error, setError] = useState(null);
    
    const [processModal, setProcessModal] = useState(false)
    
    const { project } = useContext(SubOrgContext)
    const { addNewNotifcation } = useContext(Context)

    const handleOpenProcessModal = () => {
        setProcessModal(true)
    };

    const handleOpenL2ProcessResult = () => {
        if (error) { 
            addNewNotifcation('No results available to view', 'warning')
        } else {
            setIsProcessing2Result(true)
        }
    };

    const ACTION_COMPONENTS = [
        {id: 1, name: 'L1 Processing', icon: <Layers className="mr-2 h-4 w-4" />, onclick: handleOpenProcessModal},
        {id: 2, name: 'L2 Processing', icon: <Layers className="mr-2 h-4 w-4" />, onclick: () => setIsProcessing2(true)},
        {id: 2, name: 'L2 Processing Result', icon: <Layers className="mr-2 h-4 w-4" />, onclick: handleOpenL2ProcessResult}
    ]

    return (
        <div className='w-full'>
            <div className='h-auto py-8'>
                <div className='my-3 px-2'>
                    <Breadcrumb maxPaths={3} />
                </div>

                <FileManager
                    project={project}
                    actionComponents={ACTION_COMPONENTS}/>

                <ProcessModal isOpen={processModal} setIsOpen={setProcessModal} loading={isProcessing} setLoading={setIsProcessing}/>
                
                {/* l2 processing  */}
                <L2ProcessingDialog isOpen={isProcessing2} setIsOpen={setIsProcessing2} />

                {/* l2 processing result  */}
                <L2ProcessingResult isOpen={isProcessing2Result} setIsOpen={setIsProcessing2Result} setError={setError} />
            </div>
        </div>
    )
}
