import { CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Button } from "components/ui/button"
import { Textarea } from 'components/ui/textarea'
import React, { useEffect, useState } from 'react'
import { X, Plus,} from 'lucide-react'
import WebchatWidget from './WebchatWidget'

const HomeScreen = ({loading, handleSave, emailUpdateObject}) => {
    const [chatSettings, setChatSettings] = useState({
        chatGreetingMessage: "Hi! I'm BeX AI Assistant. How can I help you today?",
        primaryColor: "#FFFFFF",
        secondaryColor: "#000000",
        textPrimaryColor: "#000000",
        textSecondaryColor: "#FFFFFF",
        assistantName: "BeX AI Assistant",
        chatWidth: 380, chatHeight: 500,
        conversationStarters: ["How to start this questionnaire?",] });
    const iconStyle = {
        accentColor:emailUpdateObject?.webChatConfig?.accentColor || 'black',
        textAccentColor:emailUpdateObject?.webChatConfig?.textAccentColor || 'white',}

    useEffect(() => {
    if(emailUpdateObject.webChatConfig){
        const {chatGreetingMessage, primaryColor, secondaryColor, textPrimaryColor, textSecondaryColor,
                assistantName, chatWidth, chatHeight, conversationStarters} = emailUpdateObject.webChatConfig

        setChatSettings((prevSettings) => ({
        ...prevSettings,
        ...(chatGreetingMessage !== undefined && { chatGreetingMessage }),
        ...(primaryColor !== undefined && { primaryColor }),
        ...(secondaryColor !== undefined && { secondaryColor }),
        ...(textPrimaryColor !== undefined && { textPrimaryColor }),
        ...(textSecondaryColor !== undefined && { textSecondaryColor }),
        ...(assistantName !== undefined && { assistantName }),
        ...(chatWidth !== undefined && { chatWidth }),
        ...(chatHeight !== undefined && { chatHeight }),
        ...(conversationStarters !== undefined && Array.isArray(conversationStarters) && { conversationStarters }),
        }));
    }
    }, [emailUpdateObject])

    const removeConversationStarter = (index) => {
        setChatSettings((prevSettings) => ({
            ...prevSettings, conversationStarters: prevSettings.conversationStarters.filter((_, i) => i !== index), }));
    };

    const addConversationStarter = () => {
        setChatSettings((prevSettings) => {
            if (prevSettings.conversationStarters.length < 5) {
            return {...prevSettings, conversationStarters: [...prevSettings.conversationStarters, "New starter"] };
            }
            return prevSettings;
        });
    };

    const handleSaveClick = () => {
        const webChatConfig = {...emailUpdateObject?.webChatConfig, ...chatSettings}
        handleSave(webChatConfig, true)
    }

return (
    <div className="flex flex-col gap-4">
        <CardHeader className="p-0">
            <CardTitle>Home Screen Configuration</CardTitle>
            <CardDescription>Customize the initial screen of your web chat</CardDescription>
        </CardHeader>
        <CardContent className="space-y-6 px-0 pb-0">
            {/* Assistant Name */}
            <div className="space-y-4">
                <h3 className="text-lg font-semibold">Name</h3>
                <div className="space-y-2">
                    <Label htmlFor="assistant-name">Assistant's name</Label>
                    <Input
                        id="assistant-name"
                        value={chatSettings.assistantName}
                        onChange={(e) =>
                        setChatSettings((prev) => ({ ...prev, assistantName: e.target.value }))
                        }
                    />
                </div>
                {/* Colors */}
                <h3 className="text-lg font-semibold">Colors</h3>
                <div className="grid grid-cols-2 gap-4">
                    {[
                        { id: "primary-color", label: "Primary color (Chat & Header)", field: "primaryColor" },
                        { id: "text-primary-color", label: "Text color (Chat & Header)", field: "textPrimaryColor" },
                        { id: "secondary-color", label: "Secondary color (User message bubble)", field: "secondaryColor" },
                        { id: "text-secondary-color", label: "Text color (User message bubble)", field: "textSecondaryColor" },
                    ].map(({ id, label, field }) => (
                        <div key={id} className="space-y-2">
                            <Label htmlFor={id}>{label}</Label>
                            <div className="flex space-x-2">
                                <Input
                                type="color"
                                id={id}
                                value={chatSettings[field]}
                                onChange={(e) =>
                                    setChatSettings((prev) => ({ ...prev, [field]: e.target.value }))
                                }
                                className="w-12 h-12 p-1"
                                />
                                <Input
                                type="text"
                                value={chatSettings[field]}
                                onChange={(e) =>
                                    setChatSettings((prev) => ({ ...prev, [field]: e.target.value }))
                                }
                                className="flex-grow"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Size */}
            <div className="space-y-4">
                <h3 className="text-lg font-semibold">Size</h3>
                <div className="grid grid-cols-2 gap-4">
                    {[
                        { id: "chat-width", label: "Width", field: "chatWidth" },
                        { id: "chat-height", label: "Height", field: "chatHeight" },
                    ].map(({ id, label, field }) => (
                        <div key={id} className="space-y-2">
                            <Label htmlFor={id}>{label}</Label>
                            <Input
                                id={id}
                                type="number"
                                value={chatSettings[field]}
                                onChange={(e) =>
                                setChatSettings((prev) => ({ ...prev, [field]: Number(e.target.value) }))
                                }
                            />
                        </div>
                    ))}
                </div>
            </div>
            {/* Greeting Message */}
            <div className="space-y-2">
                <Label htmlFor="welcome-message">Greeting message</Label>
                <Textarea
                id="welcome-message"
                value={chatSettings.chatGreetingMessage}
                onChange={(e) =>
                    setChatSettings((prev) => ({ ...prev, chatGreetingMessage: e.target.value }))
                }
                rows={3}
                />
            </div>
            {/* Conversation Starters */}
            <div className="space-y-2">
                <Label>Conversation starters</Label>
                <ul className="space-y-2">
                    {chatSettings.conversationStarters.map((starter, index) => (
                        <li key={index} className="flex items-center space-x-2">
                            <Input
                                type="text"
                                value={starter}
                                onChange={(e) => {
                                const newStarters = [...chatSettings.conversationStarters];
                                newStarters[index] = e.target.value;
                                setChatSettings((prev) => ({ ...prev, conversationStarters: newStarters }));
                                }}
                                className="flex-grow"
                            />
                            <Button
                                variant="ghost"
                                size="icon"
                                className="text-red-500 hover:text-red-700"
                                onClick={() => removeConversationStarter(index)}
                            >
                                <X className="h-4 w-4" />
                            </Button>
                        </li>
                    ))}
                </ul>
                {chatSettings.conversationStarters.length < 5 && (
                    <Button onClick={addConversationStarter} variant="outline">
                        <Plus className="h-4 w-4 mr-2" />
                        Add starter
                    </Button>
                )}
            </div>
            {/* Save Button */}
            <div className="mt-6">
                <Button className="w-full" disabled={loading} onClick={handleSaveClick}>
                    Save Changes
                </Button>
            </div>
        </CardContent>
        {/* Chat Widget */}
        <WebchatWidget 
        chatSettings={chatSettings} 
        widgetId={emailUpdateObject.address} 
        includeConvoHistory={emailUpdateObject.includeConvoHistory}
        iconStyle={iconStyle} />
    </div>
    )}
export default HomeScreen
