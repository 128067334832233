import React, { useEffect, useRef } from 'react'
import { User, Bot, Loader } from 'lucide-react'

const WebchatBody = ({emitMessage, messages, isHome, loading, chatSettings}) => {
    const chatBody = useRef(null)
    const {chatGreetingMessage, secondaryColor, textSecondaryColor, chatHeight, conversationStarters} = chatSettings
    const messageStyle = 'max-w-[75%] px-3 py-2 rounded-lg text-sm leading-[1.6]';
    const iconsStyle = 'inline-flex items-center justify-center w-[35px] h-[35px] rounded-full text-lg';
    const userStyle = 'bg-black text-white'
    const chatbotStyle = 'bg-[#9ca3af] text-white'

    useEffect(() => {
        chatBody?.current?.scrollTo({top:chatBody?.current?.scrollHeight, behavior:"smooth"})
    }, [messages])

return (
    <div className="overflow-y-auto p-4" style={{height: `${chatHeight}px`}} ref={chatBody}>
        {isHome ? (
            <div>
                <p className="text-2xl font-light mb-6">{chatGreetingMessage}</p>
                <div className="space-y-3">
                    {conversationStarters.map((starter, index) => (
                        <button 
                        key={index}
                        className="w-full text-left p-2 rounded-full transition-colors"
                        style={{ 
                            backgroundColor: secondaryColor,
                            color: textSecondaryColor,
                        }}
                        onClick={() => emitMessage(starter)}
                        >
                            {starter}
                        </button>
                    ))}
                </div>
            </div>
        ): (
            <>
                {messages.map((msg, index) => (
                    <div
                    key={index}
                    className={`flex my-3 gap-2 ${
                        msg.type === "user" ? "justify-end" : "justify-start"
                    }`}
                    >
                        <span
                            className={`${iconsStyle} ${msg.type === "user"? userStyle : chatbotStyle}`}
                        >
                            {msg.type === "user" ? <User strokeWidth={1.5} /> : <Bot strokeWidth={1.5} />}
                        </span>
                        <p
                            className={`${messageStyle} ${msg.type === "user"? userStyle : chatbotStyle}`}
                        >
                            {msg.text}
                        </p>
                    </div>
                ))}
                {loading && (
                <div className="flex my-2 justify-start gap-2">
                    <span className={`${iconsStyle} ${chatbotStyle}`} >
                        <Bot strokeWidth={1.5} />
                    </span>
                    <p className={`${messageStyle} flex items-center gap-2 ${chatbotStyle}`}>
                        <Loader className="animate-spin"/> Typing...
                    </p>
                </div>
                )}
            </>
        )}
    </div>
  )
}

export default WebchatBody