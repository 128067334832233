import React, { useEffect, useState } from 'react'
import { Send, Mic } from 'lucide-react'
import { Textarea } from 'components/ui/textarea';
import { BsRecordCircle } from 'react-icons/bs';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
const WebchatFooter = ({loading, emitMessage}) => {
    const [userMessage, setUserMessage] = useState("");
    const [recording, setRecording] = useState(false);
    const [recordingTime, setRecordingTime] = useState(0); 
    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    useEffect(() => {
        let timer;
        if (recording) {
          timer = setInterval(() => {
            setRecordingTime((prevTime) => prevTime + 1);
          }, 1000);
        } else {
          clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [recording]);

    const handleSendMessage = () => {
        if (userMessage.trim() && !loading) {
            emitMessage(userMessage)
            setUserMessage("");
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();  
          handleSendMessage(); 
        }
    };

    const handleVoiceRecording = () => {
        if (!browserSupportsSpeechRecognition) {
          window.alert("Your browser does not support speech recognition.");
          return;
        }
    
        if (recording) {
          SpeechRecognition.stopListening();
          setRecording(false);
          if (transcript.trim() && !loading) {
            emitMessage(transcript)
          }
        } else {
          resetTranscript(); 
          setRecordingTime(0);
          SpeechRecognition.startListening({ continuous: true });
          setRecording(true);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes > 0 ? `${minutes}m ` : ""}${seconds}s`;
    };

return (
    <div className="p-3 bg-white border-t">
        <div className="flex items-center space-x-2">
            <Textarea
            onChange={(e) => setUserMessage(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            value={userMessage}
            rows='1'
            type="text"
            placeholder="Type Something....."
            className="flex-grow bg-transparent min-h-1 text-gray-800 placeholder-gray-400 border-gray-300 resize-none"
            />
            <div className="flex items-center gap-2">
                <div className="cursor-pointer" onClick={handleVoiceRecording}>
                    {listening ? 
                        <BsRecordCircle size={21} className="text-[#b1092a] animate-pulse" /> : 
                        <Mic className="h-6 w-6 text-black hover:text-[#8c8c8c]"/>
                    }
                </div>
                <Send className="h-5 w-5 text-black cursor-pointer hover:text-[#8c8c8c]" onClick={handleSendMessage}/>
            </div>
        </div>
        {recording && (
        <div className="mt-3 w-full p-3 border rounded-lg shadow-md">
            <div className="flex items-center justify-between mb-2">
                <span className="text-xs font-semibold text-gray-500">Recording Time</span>
                <span className="text-sm font-semibold">{formatTime(recordingTime)}</span>
            </div>
            <div className="border-t border-gray-300 pt-2">
                <span className="text-xs font-semibold text-gray-500">Transcript</span>
                <div className="mt-1 text-sm text-gray-800 break-words">{transcript || "..."}</div>
            </div>
        </div>
        )}
    </div>
  )
}

export default WebchatFooter